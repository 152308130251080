
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME = companyName.companyName ?? 'Company Name';
// const BASE_URL = "http://192.168.9.8:5000/api/";
// const IMG_URL = "http://mlmadmin.demo5.iossmlm.com/uploads/images/";
// const BASE = "http://192.168.9.9:5002";
// const API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
// const LEAD_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
// // const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const DEFAULT_KEY = '1078_'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY,
//     LEAD_URL
// }


//demo
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME = companyName.companyName ?? 'Company Name';
// // const BASE_URL = "http://167.235.248.102:7003/api/";
// const BASE_URL = "http://162.19.146.134:108/api/";
// const IMG_URL = "http://162.19.146.134:108/api/uploads/images/";
// const BASE = "http://162.19.146.134:108/";
// const API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
// const LEAD_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
// // const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const DEFAULT_KEY = '1078_'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY,
//     LEAD_URL
// }


// //live
import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME = companyName.companyName ?? 'Company Name';
// const BASE_URL = "http://167.235.248.102:7003/api/";
const BASE_URL = "https://admin.geniusbalancing.com/node_api/";
const IMG_URL = "https://admin.geniusbalancing.com/node_api/uploads/images/";
const BASE = "https://admin.geniusbalancing.com/";
const API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
const LEAD_URL = "https://admin.geniusbalancing.com/node_api/";
// const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
const DEFAULT_KEY = '1078_'
export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE,
    DEFAULT_KEY,
    LEAD_URL
}




