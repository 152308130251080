import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CurrencyDecimalFormat } from "../../helper";

//style
import style from "./epin.module.scss";

function Tiles(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const Currency = useSelector((state) => state.curr);
  useEffect(() => {
    let ewalletTiles = props.tilesData.map((items) => {
      var background;
      var img;
      var amount;
      switch (items.text) {
        case "active_epin":
          background = style.green;
          img = "Paid-w.png";
          amount = items.amount;
          break;
        case "epin_balance":
          img = "E-Wallet-w.png";
          background = style.blue;
          amount = `${Currency.currentCurr} ${CurrencyDecimalFormat(
            items.amount * Currency.value,
            Currency.precision
          )}`;
          break;
        default:
          background = style.yellow;
          img = "Pending-w.png";
          amount = items.amount;
      }
      // background = style.blue
      return {
        amount: amount,
        text: items.text,
        background: background,
        // img: require("../../../assets/images/logos/" + img),
        img:img
      };
    });
    setData(ewalletTiles);
  }, [setData, props.tilesData, Currency]);
  return (
    <div className={`${style.epinTiles}`}>
      {data.map((tiles, index) => {
        return (
          <div className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_Tiles} d-flex`
              : `${style.Tiles} d-flex`
          }`} key={index}>
            <div className={`${style.leftIcon} ${tiles.background}`}>
              <img
                src={require("../../../assets/images/logos/" + tiles.img).default}
                alt=""
              />
            </div>
            <div className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_tilesData}`
                  : `${style.tilesData}`
              }`}>
              <h4>{t("Epin." + tiles.text)}</h4>
              <span className={style.TilesAmount}>{tiles.amount}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Tiles;
