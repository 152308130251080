import React from 'react';
import style from './auth.module.scss';
import {IMG_URL} from '../../../configuration/apiconfig';
import Logo from '../../../assets/images/logos/login_logo.gif';
import AppInfoservice from '../../../service/common/Appinfo';
import { useState,useEffect } from 'react';

const Brand = () => {
   
    return (
        <div className={`d-block mt-2 ${style.loginNavBrand}`}>
            {/* <img src={IMG_URL+"logos/logo_login.png"} alt="" className={style.loginNavBrandImg}/> */}
            <center><img src={Logo} alt="" className={style.loginNavBrandImg}/></center>
        </div>
    );
};

export default Brand; 