import React, { useEffect } from 'react'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from "html-to-draftjs";
import { useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { useSelector } from 'react-redux';
import style from './mail.module.scss'
import { useTranslation } from 'react-i18next';
import { Card, Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import ReplicaSiteService from '../../../service/replica/replica';
import { AlertBs } from '../common';
import { Image } from 'react-bootstrap';
import { encode } from "html-entities";
import ReactPlayer from 'react-player';
import { width } from '@mui/system';
import ReactSummernote from "react-summernote";
// import 'react-summernote/dist/react-summernote.css';
// import 'react-summernote/dist/react-summernote';
import 'react-summernote/dist/react-summernote.css';
import 'react-summernote/dist/react-summernote';


function ServiceContent() {
    const service = new ReplicaSiteService();
    const { t } = useTranslation();
    const [state, setstate] = useState({
        selectedFile: "",
        lefturl: "",
        centerurl: '',
        righturl: '',
        align: '',
        leftfilesuccess: false,
        centerfilesuccess: false,
        rightfilesuccess: false,
        textSuccess: false,
        lefttype: '',
        righttype: '',
        centertype: '',
        uploadedTrue: false,
        content: {
            serviceContent: ""
        },
        alert: {
            message: '',
            varient: '',
            show: false
        }
    })
    const currentTheme = useSelector((state) => state.Theme)
    // const initialiseEditor = () => {
    //     const html = "";
    //     const contentBlock = htmlToDraft(html);
    //     if (contentBlock) {
    //         // console.log('contentblock',contentBlock)
    //         const contentState = ContentState.createFromBlockArray(
    //             contentBlock.contentBlocks
    //         );
    //         const editorState = EditorState.createWithContent(contentState);
    //         setstate((prev) => ({
    //             ...prev,
    //             editorState
    //         }))
    //     }
    // };

    const editorChange = (editorState, type) => {
        console.log('typeee', editorState)
        const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        let content = state.content
        content.serviceContent = data;
        setstate((prev) => ({
            ...prev,
            ...prev.content,
            textSuccess: true,
            editorState,
            align: type
        }));
    };

    useEffect(() => {
        // initialiseEditor()
        getTextContent();
        getReplicaContents();
    }, [])


    const getTextContent = () => {
        service.getTextContent().then((res) => {
            if (res.status) {
                const html = res.data;
                const contentBlock = htmlToDraft(html);
                if (contentBlock) {
                    // console.log('contentblock',contentBlock)
                    const contentState = ContentState.createFromBlockArray(
                        contentBlock.contentBlocks
                    );
                    const editorState = EditorState.createWithContent(contentState);
                    setstate((prev) => ({
                        ...prev,
                        editorState,
                        textSuccess: false
                    }))
                }
            } else {
            }
        })
    }

    const getReplicaContents = () => {
        service.getReplicaContents().then((res) => {
            if (res.status) {
                setstate((prev) => ({
                    ...prev,
                    lefttype: res.data.lefttype,
                    lefturl: res.data.lefturl,
                    leftfilesuccess: res.data.uploadLeft,
                    centertype: res.data.centertype,
                    centerurl: res.data.centerurl,
                    centerfilesuccess: res.data.uploadCenter,
                    righttype: res.data.righttype,
                    righturl: res.data.righturl,
                    rightfilesuccess: res.data.uploadRight
                }))
            }
        })
    }
    const selectFileHandler = (e) => {
        const { name, value } = e.target
        console.log('namejk', e.target.files[0].type)

        setstate((prev) => ({
            ...prev,
            selectedFile: e.target.files[0],

        }))
        switch (name) {
            case 'left':
                setstate((prev) => ({
                    ...prev,
                    lefturl: URL.createObjectURL(e.target.files[0]),
                    lefttype: e.target.files[0].type.split('/')[0],
                    align: name
                }))
                break;
            case 'center':
                setstate((prev) => ({
                    ...prev,
                    centerurl: URL.createObjectURL(e.target.files[0]),
                    centertype: e.target.files[0].type.split('/')[0],
                    align: name

                }))
                break;
            case 'right':
                setstate((prev) => ({
                    ...prev,
                    righturl: URL.createObjectURL(e.target.files[0]),
                    righttype: e.target.files[0].type.split('/')[0],
                    align: name
                }))
        }
    }
    console.log('typeeee', state.type)
    const removeHandler = (position) => {
        console.log('hjhkjhkhih', position)
        switch (position) {
            case 'left':
                setstate((prev) => ({
                    ...prev,
                    lefturl: '',
                    leftfilesuccess: false,
                    lefttype: ''
                }))
                break;
            case 'center':
                setstate((prev) => ({
                    ...prev,
                    centerurl: '',
                    centerfilesuccess: false,
                    centertype: ''
                }))
                break;
            case 'right':
                setstate((prev) => ({
                    ...prev,
                    righturl: '',
                    rightfilesuccess: false,
                    righttype: ''
                }))
                break;

        }
    }
    console.log('selectedfilee', state.lefturl)
    const uploadDataHandler = (position) => {
        console.log('position', position)
        console.log('alignnnn', state.align)
        let formdata = new FormData()
        formdata.append('textfield', state.content.serviceContent)
        formdata.append('file', state.selectedFile)
        formdata.append('align', state.align)
        service.postserviceData(formdata).then((res) => {
            if (res.status) {
                // initialiseEditor()
                switch (position) {
                    case 'left':
                        setstate((prev) => ({
                            ...prev,
                            leftfilesuccess: true,

                            content: {
                                ...prev.content,
                                serviceContent: ''
                            },
                            selectedFile: '',
                            alert: {
                                show: true,
                                varient: 'success',
                                message: 'Common.leftsidefilesuccess'
                            }
                        }))
                        // closeAlert();
                        break;
                    case 'center':
                        setstate((prev) => ({
                            ...prev,
                            centerfilesuccess: true,
                            content: {
                                ...prev.content,
                                serviceContent: ''
                            },
                            selectedFile: '',
                            alert: {
                                show: true,
                                varient: 'success',
                                message: 'Common.centersideuplodedsuccess'
                            }
                        }))
                        break;
                    case 'right':
                        setstate((prev) => ({
                            ...prev,
                            rightfilesuccess: true,
                            content: {
                                ...prev.content,
                                serviceContent: ''
                            },
                            selectedFile: '',
                            alert: {
                                show: true,
                                varient: 'success',
                                message: 'Common.rightsidefilesucces'
                            }
                        }))
                        break;
                    case 'text':
                        setstate((prev) => ({
                            ...prev,
                            textSuccess: true,
                            alert: {
                                show: true,
                                varient: 'success',
                                message: 'Common.textsucces'
                            }
                        }))
                        break;
                }

            }
            else {
                setstate((prev) => ({
                    ...prev,
                    alert: {
                        show: true,
                        varient: 'danger',
                        message: 'Common.datauploadingfailed'
                    }
                }))
            }
        })
    }
    const closeAlert = () => {
        setstate((prev) => ({
            ...prev,
            alert: {
                show: false,
                varient: '',
                message: ''
            }
        }))
    }
    console.log('editordataa', state.lefttype)
    const editorHandler = (content) => {

    }
    return (
        <div className={style.mailboxMainContent}>
            <div>
                <AlertBs {...state.alert} close={closeAlert} />
                <Card>
                    <Card.Body>
                        <Form.Group>

                            <Form.Label className={style.required}>
                                {t("Common.textContent")}
                            </Form.Label>
                            {/* <ReactSummernote
                                // value={value}
                                onInputChange={editorHandler}
                                options={{
                                    
                                    height: 300,
                                    dialogsInBody: true,
                                    toolbar: [
                                        ['style', ['style']],
                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                        ['fontname', ['fontname']],
                                        ['fontsize', ['fontsize']],
                                        ['color', ['color']],
                                        ['para', ['ul', 'ol', 'paragraph']],
                                        ['table', ['table']],
                                        ['insert', ['link', 'picture', 'video']],
                                        ['view', ['fullscreen', 'codeview']],
                                        ['help', ['help']],
                                    ],
                                    plugins: {
                                        table: {},
                                      },
                                    callbacks: {
                                        onImageUpload: (files) => {
                                            const imageFile = files[0];
                                            const reader = new FileReader();
                                            reader.onload = () => {
                                                const imageDataUrl = reader.result;
                                                const image = new Image();
                                                image.src = imageDataUrl;
                                                image.onload = () => {
                                                    const canvas = document.createElement('canvas');
                                                    canvas.width = image.width;
                                                    canvas.height = image.height;
                                                    const context = canvas.getContext('2d');
                                                    context.drawImage(image, 0, 0, image.width, image.height);
                                                    const dataUrl = canvas.toDataURL('image/jpeg');
                                                    ReactSummernote.insertImage(dataUrl);
                                                };
                                            };
                                            reader.readAsDataURL(imageFile);
                                        },
                                    },
                                }}
                            /> */}

                            <Editor
                                editorState={state.editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName={`${currentTheme == "theme-dark"
                                    ? `${style.dark_mode_Editor}`
                                    : `${style.Editor}`
                                    }`}
                                onEditorStateChange={(editorState) => editorChange(editorState, 'text')}
                                placeholder={t("Common.typeyourdetails")}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Button
                                hidden={!state.textSuccess}
                                onClick={() => uploadDataHandler('text')}

                            // className={style.uploadButton}
                            // hidden={state.leftfilesuccess}
                            >
                                <i className="fa fa-arrow-circle-o-up"></i>
                                {t("Button.upload")}
                            </Button>
                        </Form.Group>
                        {/* <div className='row'>
                            <div className='col-lg-12'>
                                <label><b>Left side File Content</b></label>
                            </div>
                        </div><br/> */}

                        <div className='row'>

                            <div className='col-lg-4'>
                                <Form.Group>
                                    <Form.Label className={style.required}>
                                        {t("Common.leftContent")}
                                    </Form.Label>
                                    {/* <Form.Label>Upload Materials</Form.Label> */}
                                    <Form.Control name='left' type="file" onChange={(e) => selectFileHandler(e)} disabled={state.lefturl} />
                                    <Form.Text className='text-danger'>
                                        {t("Common.allowedTypes")}
                                    </Form.Text>
                                </Form.Group>
                                {
                                    state.lefttype != '' && (
                                        state.lefttype === "video" ?
                                            <div>
                                                <ReactPlayer
                                                    url={state.lefturl}
                                                    controls={true}
                                                    pip={true}
                                                    
                                                    width='50%'
                                                    height='50%'
                                                />
                                            </div>

                                            : <div style={{ "width": '150px', "height": '93px' }}>

                                                <img src={state.lefturl} className={state.lefturl ? `${style.imgstyle}` : ""} /></div>
                                    )

                                }

                                <div className='mb-2 mt-3'>
                                    {state.lefturl && (
                                        <Button
                                            onClick={() => removeHandler('left')}
                                            className={style.RecieptRemoveButton}
                                        >
                                            <i className="fa fa-times"></i>
                                            Remove
                                        </Button>
                                    )}
                                </div>

                                <Form.Group>
                                    <Button
                                        onClick={() => uploadDataHandler('left')}
                                        disabled={!state.lefturl}
                                        className={style.uploadButton}
                                        hidden={state.leftfilesuccess}
                                    >
                                        <i className="fa fa-arrow-circle-o-up"></i>
                                        {t("Button.upload")}
                                    </Button>
                                </Form.Group>
                            </div>
                            <div className='col-lg-4'>
                                <Form.Group>
                                    <Form.Label className={style.required}>
                                        {t("Common.centerContent")}
                                    </Form.Label>
                                    {/* <Form.Label>Upload Materials</Form.Label> */}
                                    <Form.Control name='center' type="file" onChange={(e) => selectFileHandler(e)} disabled={state.centerurl} />
                                    <Form.Text className='text-danger'>
                                        {t("Common.allowedTypes")}
                                    </Form.Text>
                                </Form.Group>
                                {
                                    state.centertype != '' && (
                                        state.centertype === "video" ?
                                            <div>
                                                <ReactPlayer
                                                    url={state.centerurl}
                                                    controls={true}
                                                    pip={true}
                                                    
                                                    width='50%'
                                                    height='50%'
                                                />
                                            </div>

                                            : <div style={{ "width": '150px', "height": '93px' }}>
                                                <img src={state.centerurl} className={state.centerurl ? `${style.imgstyle}` : ""} /></div>
                                    )

                                }

                                <div className='mb-2 mt-3'>
                                    {state.centerurl && (
                                        <Button
                                            onClick={() => removeHandler('center')}
                                            className={style.RecieptRemoveButton}
                                        >
                                            <i className="fa fa-times"></i>
                                            Remove
                                        </Button>
                                    )}
                                </div>
                                <Form.Group>
                                    <Button
                                        disabled={!state.centerurl}
                                        className={style.uploadButton}
                                        onClick={() => uploadDataHandler('center')}
                                        hidden={state.centerfilesuccess}
                                    >
                                        <i className="fa fa-arrow-circle-o-up"></i>
                                        {t("Button.upload")}
                                    </Button>
                                </Form.Group>
                            </div>
                            <div className='col-lg-4'>
                                <Form.Group>
                                    <Form.Label className={style.required}>
                                        {t("Common.rightContent")}
                                    </Form.Label>
                                    {/* <Form.Label>Upload Materials</Form.Label> */}
                                    <Form.Control name='right' type="file" onChange={(e) => selectFileHandler(e)} disabled={state.righturl} />
                                    <Form.Text className='text-danger'>
                                        {t("Common.allowedTypes")}
                                    </Form.Text>
                                </Form.Group>
                                {
                                    state.righttype != '' && (
                                        state.righttype === "video" ?
                                            <div>
                                                <ReactPlayer
                                                    url={state.righturl}
                                                    controls={true}
                                                    pip={true}
                                                    
                                                    width='50%'
                                                    height='70%'
                                                />
                                            </div>

                                            : <div style={{ "width": '150px', "height": '93px' }}>
                                                <img src={state.righturl} className={state.righturl ? `${style.imgstyle}` : ""} /></div>
                                    )

                                }
                                <div className='mb-2 mt-3'>
                                    {state.righturl && (
                                        <Button
                                            onClick={() => removeHandler('right')}
                                            className={style.RecieptRemoveButton}
                                        >
                                            <i className="fa fa-times"></i>
                                            Remove
                                        </Button>
                                    )}
                                </div>

                                <Form.Group>
                                    <Button
                                        disabled={!state.righturl}
                                        className={style.uploadButton}
                                        onClick={() => uploadDataHandler('right')}
                                        hidden={state.rightfilesuccess}
                                    >
                                        <i className="fa fa-arrow-circle-o-up"></i>
                                        {t("Button.upload")}
                                    </Button>
                                </Form.Group>
                            </div>
                        </div>

                    </Card.Body>
                    <Card.Footer>
                        {/* <Button onClick={uploadDataHandler}>{t('Button.upload')}</Button> */}
                    </Card.Footer>
                </Card>

            </div>
        </div>

    )
}

export default ServiceContent
