import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import companyName from '../../../store/mobxStore/companyName'
import { useTranslation } from 'react-i18next'
import { PageTitle } from '../../components/common'
import CommissionReportContent from '../../components/Report/CommissionReportContent'
import { useState } from 'react'
import moment from 'moment'
import ReportsService from '../../../service/Report/CommissionReport'
import Spinner from '../../shared/Spinner'
import { useHistory } from 'react-router'
function CommissionReport() {
    const { t } = useTranslation();
    const history=useHistory();
    const service = new ReportsService();
    const [state, setstate] = useState({
        loader: false,
        filter: {
            start: moment().startOf('month'),
            end: moment(),
            label: moment().startOf('month').format('MMMM D,YYYY') + '-' + moment().format('MMMM D,YYYY')
        },
        report: {
            totalRows: 0,
            data: [],
            pending: true,
            inPage: 1,
            perPage: 10

        }
    })
    useEffect(()=>{
        loadData(state.report.inPage,state.report.perPage,state.filter.start.format('Y-MM-DD'),state.filter.end.format('Y-MM-DD'))
    },[])
    const loadData = (inPage = state.report.inPage, perPage = state.report.perPage, startDate = '', endDate = '') => {
        let start = (inPage - 1) * perPage
        service.getReport(start, perPage, startDate, endDate).then((res) => {
            if (res.status) {
                setstate((prev) => ({
                    ...prev,
                    report: {
                        ...prev.report,
                        totalRows: res.data.total_row,
                        data: res.data.data,
                        pending: false
                    }

                }))
            } else {
                if (res.error.code === 1002) {
                    history.push('/logout');
                } else if (res.error.code === 1057) {
                   history.push({
                        pathname: '/dashboard',
                        state: {
                            error: true,
                            message: 'permissionDenied'
                        }
                    })
                }
            }
        })
    }

    const filterChange=(start,end)=>{
        setstate((prev)=>({
            ...prev,
            filter:{
                ...prev.filter,
                start:start,
                end:end,
                label:start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')
            }
        }))
    }

    const resetButton=()=>{
        setstate((prev)=>({
            ...prev,
            report:{
                ...prev.report,
                perPage:10,
                inPage:1
            }
        }))
        loadData(1,10)
    }

    const submitFilter=()=>{
        let StartDate=state.filter.start.format('Y-MM-D')
        let EndDate=state.filter.end.format('Y-MM-DD')
        loadData(state.report.inPage,state.report.perPage,StartDate,EndDate)
    }

    const paginationchange=(inPage,perPage=state.report.perPage)=>{
        setstate((prev)=>({
            ...prev,
            report:{
                ...prev.report,
                inPage:inPage,
                perPage:perPage
            }
        }))
        let StartDate=state.filter.start.format('Y-MM-DD')
        let EndDate=state.filter.end.format('Y-MM-DD')
        loadData(inPage,perPage,StartDate,EndDate)
    }
    if(state.loader){
        return <Spinner/>
    }
    return (
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.commissionReport')}</title>
            </Helmet>
            <PageTitle title="commissionReport" buttonOn={false} />
            <CommissionReportContent
                {...state}
                changeDate={filterChange}
                resetButton={resetButton}
                submitFilter={submitFilter}
                paginationchange={paginationchange}
            />
        </div>
    )
}

export default CommissionReport