import React from 'react';
import DataTable from "react-data-table-component";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import "react-data-table-component-extensions/dist/index.css";
import { CurrencyDecimalFormat } from '../../helper';
import style from './invoice.module.scss';
function ReportTable(props) {
    const { t } = useTranslation();
    const Currency = useSelector(state => state.curr)
    const columns = [
        {
            name: t('Common.category'),
            selector: row => t(`Common.${row.category}`),
            //   width : "30%"
        },
        {
            name: t('Common.totalAmount'),
            selector: row => Number(row.amount).toFixed(Currency.Currency.precision),
            cell: (row) => <span className={`${style.primaryBadge} ${style.badge}`}>{Currency.currentCurr} {CurrencyDecimalFormat(row.amount * Currency.value, Currency.precision)}</span>
        },
        {
            name: t('Common.tax'),
            selector: row => Number(row.tax).toFixed(Currency.precision),
            cell: (row) => <span className={`${style.primaryBadge} ${style.badge}`}>{Currency.currentCurr} {CurrencyDecimalFormat(row.tax * Currency.value, Currency.precision)}</span>
        },
        {
            name: t('Common.serviceChange'),
            selector: row => Number(row.serviceCharge).toFixed(Currency.precision),
            cell: (row) => <span className={`${style.primaryBadge} ${style.badge}`}>{Currency.currentCurr} {CurrencyDecimalFormat(row.serviceCharge * Currency.value, Currency.precision)}</span>
        },
        {
            name: t('Common.amountPayable'),
            selector: row => Number(row.amountPayable).toFixed(Currency.precision),
            cell: (row) => <span className={`${style.primaryBadge} ${style.badge}`}>{Currency.currentCurr} {CurrencyDecimalFormat(row.amountPayable * Currency.value, Currency.precision)}</span>
        },

        {
            name: t('Common.transactionDate'),
            selector: row => row.transaction_date
        }
    ];
    const handlePerRowsChange = (perPage, inPage) => {
        props.paginationchange(
            inPage,
            perPage
        )
    };
    const handlePageChange = inPage => {
        props.paginationchange(
            inPage,
            props.perPage
        )
    };
    return (
        <div>
            <DataTable
                columns={columns}
                data={props.data}
                highlightOnHover
                progressPending={props.pending}
                progressComponent={<div>Loading</div>}
                persistTableHead
                noHeader
                pagination
                paginationServer
                responsive
                paginationTotalRows={props.totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                noDataComponent={t('Common.noDataRecordsToDisplay')}
            // onSort={handleOnSort}
            />
        </div>
    )
}

export default ReportTable
