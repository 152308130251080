// const { BASE_URL, DEFAULT_KEY } = require("../../configuration/apiconfig");
// const { UserStore } = require = ("../../store/UserStore");
import { BASE_URL,DEFAULT_KEY } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";
export default class ReplicaSiteService {
    key = localStorage.getItem('apiKey')


    // https://demo3.infinitemlmdemo.com/enson/backoffice/login/valid_user
    async getHeader(replica_user, admin_user) {

        const response = await fetch(BASE_URL + 'replica/home?replica_user=' + replica_user, {     //url
            method: 'GET',                 //method
            headers: {
                'Content-Type': 'application/json',
                // 'api-key': localStorage.getItem('apiKey')
                'api-key':DEFAULT_KEY

            },
        });
        const res = await response.json();
        return res;
    }
    async getBanner(replica_user) {
        const response = await fetch(BASE_URL + 'replica/load_top_banner?replica_user=' + replica_user, {     //url
            method: 'GET',                 //method
            headers: {
                'Content-Type': 'application/json',
                // 'api-key': localStorage.getItem('apiKey')
                'api-key'       : DEFAULT_KEY 

            },
        });
        const res = await response.json();
        return res;
    }
    async getServices(replica_user) {
        const response = await fetch(BASE_URL + 'replica/ourServices?replica_user=' + replica_user, {     //url
            method: 'GET',                 //method
            headers: {
                'Content-Type': 'application/json',
                // 'api-key': localStorage.getItem('apiKey')
                'api-key'       : DEFAULT_KEY 

            },
        });
        const res = await response.json();
        return res;
    }
    async getRemotesessions(replica_user) {
        const response = await fetch(BASE_URL + 'replica/remoteSessions?replica_user=' + replica_user, {     //url
            method: 'GET',                 //method
            headers: {
                'Content-Type': 'application/json',
                // 'api-key': localStorage.getItem('apiKey')
                'api-key'       : DEFAULT_KEY

            },
        });
        const res = await response.json();
        
        return res;
    }
    async getBiofeedBack(replica_user) {
        const response = await fetch(BASE_URL + 'replica/biofeadback?replica_user=' + replica_user, {     //url
            method: 'GET',                 //method
            headers: {
                'Content-Type': 'application/json',
                // 'api-key': localStorage.getItem('apiKey')
                'api-key'       : DEFAULT_KEY

            },
        });
        const res = await response.json();
        
        return res;
    }
    async getHomecontent(replica_user) {
        const response = await fetch(BASE_URL + 'replica/homeService?replica_user=' + replica_user, {     //url
            method: 'GET',                 //method
            headers: {
                'Content-Type': 'application/json',
                // 'api-key': localStorage.getItem('apiKey')
                'api-key'       : DEFAULT_KEY 

            },
        });
        const res = await response.json();
        
        return res;
    }
    async getPolicy(replica_user) {
        const response = await fetch(BASE_URL + 'replica/policy?replica_user=' + replica_user, {     //url
            method: 'GET',                 //method
            headers: {
                'Content-Type': 'application/json',
                // 'api-key': localStorage.getItem('apiKey')
                'api-key'       : DEFAULT_KEY 

            },
        });
        const res = await response.json();
        return res;
    }

    async postContact(contactData) {
        return new Promise((resolve, reject) => {
            fetch(BASE_URL + 'replica/contact', {     //url
                method: 'POST',                 //method
                headers: {                     //passing header 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'api-key': localStorage.getItem('apiKey')
                    'api-key'       : DEFAULT_KEY 
                },
                body: JSON.stringify(contactData)  //convert to json
            })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    async postBookingdata(BookingData) {
        return new Promise((resolve, reject) => {
            fetch(BASE_URL + 'replica/bookingRequest', {     //url
                method: 'POST',                 //method
                headers: {                     //passing header 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'api-key': localStorage.getItem('apiKey')
                    'api-key'       : DEFAULT_KEY
                },
                body: JSON.stringify(BookingData)  //convert to json
            })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    async postserviceData(serviceData) {
        return new Promise((resolve, reject) => {
            fetch(BASE_URL + 'replicaService/servicePost ', {     //url
                method: 'POST',                 //method
                headers: {                     //passing header 
                    // 'Accept'        : 'application/json',
                    // 'Content-Type'  : 'application/json',
                    // 'api-key': localStorage.getItem('apiKey'),
                    'api-key'       : DEFAULT_KEY,
                    'access-token': UserStore.key
                },
                body: serviceData  //convert to json
            })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    async getTextContent() {
        return new Promise((resolve, reject) => {
            fetch(BASE_URL + 'replicaService/getText', {     //url
                method: 'GET',                 //method
                headers: {                     //passing header 
                    // 'Accept'        : 'application/json',
                    // 'Content-Type'  : 'application/json',
                    // 'api-key': localStorage.getItem('apiKey'),
                    'api-key'       : DEFAULT_KEY,
                    'access-token': UserStore.key
                },
               
            })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    async getReplicaContents() {
        return new Promise((resolve, reject) => {
            fetch(BASE_URL + 'replicaService/getReplicaContents', {     //url
                method: 'GET',                 //method
                headers: {                     //passing header 
                    // 'Accept'        : 'application/json',
                    // 'Content-Type'  : 'application/json',
                    // 'api-key': localStorage.getItem('apiKey'),
                    'api-key'       : DEFAULT_KEY,
                    'access-token': UserStore.key
                },
               
            })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    async setLanguage(id, user_name) {
        return new Promise((resolve, reject) => {

            fetch(BASE_URL + 'replica/change_replica_language', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'api-key': localStorage.getItem('apiKey')
                    'api-key'       : DEFAULT_KEY  
                    // 'access-token'  : UserStore.key
                },
                body: JSON.stringify(id)
            })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    async getApi(admin) {
        const response = await fetch(`${BASE_URL}common/api_key?admin_user_name=${admin}`, {     //url
            method: 'GET',                 //method
            headers: {                     //passing header 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'api-key': DEFAULT_KEY,
            }
        })
        const result = await response.json();
        return result;
    }

    //https://demo3.infinitemlmdemo.com/enson/backoffice/api/replica/policy?replica_user=user01
    //https://demo3.infinitemlmdemo.com/enson/backoffice/api/replica/load_top_banner?policy=user01
    //https://demo3.infinitemlmdemo.com/enson/backoffice/api/replica/home?replica_user=user01



}
