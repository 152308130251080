import { BASE_URL,DEFAULT_KEY } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";
export default class ReportsService {
    async getReport(start = 0, length = 10, startDate = '', endDate = '') {
        const response = await fetch(`${BASE_URL}report/commissionReport?start=${start}&length=${length}&start_date=${startDate}&end_date=${endDate}`, {     //url
            method: 'GET',                 //method
            headers: {                     //passing header 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'api-key': DEFAULT_KEY,
                'access-token': UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }
}