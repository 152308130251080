
import React from 'react'
import {useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
//components
import PageTitle from '../../../components/common/pageTitle';
import MainContent from '../../../components/dashboard/MainContent';
//service
import DashboardService from '../../../../service/dashboard/Dashboard'
import Spinner from '../../../shared/Spinner'
import { withRouter } from 'react-router';
import companyName from '../../../../store/mobxStore/companyName';
import Profiles from '../../../components/dashboard/profiles';


import { Row, Col } from 'react-bootstrap';

const UnHome = (props) => {

    const [state, setState] = useState({
        memberStatus: false,
        loading: true,
        alert: {
            show: false,
            varient: '',
            message: ''
        }
    })

    const service = new DashboardService();
    const { t } = props


  

useEffect(() => {
        const service = new DashboardService();
        const { state } = props.location;
        if (state) {
            if (state.error) {
                setState(prevstate => ({
                    ...prevstate,
                    alert: {
                        show: true,
                        varient: 'danger',
                        message: 'validation.' + state.message
                    }
                }))
            }
        }
        service.DashboardLoad().then((res) => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    ...res.data
                }));

               
            } else {
                props.history.push('/logout');
            }
           
        })
    // },[]) 
   
     }, [props.history, props.location])

    const FiterGraph = (range) => {
        service.JoiningGraphFilter(range).then((res) => {
            if (res.status) {
                setState(prev => ({
                    ...prev,
                    joining_graph_data_new: res.data.joining_graph_data_new
                }))
            } else {
                props.history.push('/logout');
            }
        })
    }

    const TilesFitler = (type, range) => {
        service.TileFilter(type, range).then((res) => {
            if (res.status) {
                let newData = state.tiles.map(object => {
                    if (object.text === type) {
                        return res.data
                    } else {
                        return object
                    }
                })
                setState(prevState =>({
                    ...prevState,
                    tiles: newData
                }))
            } else {
                props.history.push('/logout');
            }
        })
    }

    const closeAlert = () => {
        setState(prevState =>({
            ...prevState,
            alert: {
                show: false,
                message: '',
                varient: ''
            }
        }))
    }



    return (
        <div>
           {state.profile &&
             
             <div>
                 <Row>
                     <Col xl={4} lg={4} md={12}>
                         <div>
                             <div><img src={state.profile.user_photo} alt="" /></div>
                             <div>
                                 <strong>{state.profile.full_name} </strong>
                                 <span>{state.profile.user_name}</span>
                             </div>
                         </div>

                     </Col>
                    
                     <Col xl={2} lg={2} md={6}>
                         <div>
                             {state.profile.membership_package &&
                                 <div>
                                     <span>
                                         {t('Dashboard.membershipPackage')}
                                         <strong>{state.profile.membership_package.name ?? 'NA'} </strong>
                                     </span>
                                 </div>
                             }
                             {state.profile.rank &&
                                 <div>
                                     <span>
                                         {t('Common.rank').toUpperCase()}
                                         <strong>{state.profile.rank.curent_rank}</strong>
                                     </span>
                                 </div>
                             }

                         </div>
                     </Col>
                     <Col xl={2} lg={2} md={6}>
                         <div>
                             <div>
                                 <span>
                                 {t('Common.placement')}:
                                     <strong>{state.profile.placement}</strong>
                                 </span>
                             </div>
                             {/* <div className={style.profile_other_box_cnt}>
                                 <span>
                                 {t('Common.position')}:
                                     <strong>Left</strong>
                                 </span>
                             </div> */}
                         </div>
                     </Col>
                 </Row>
             </div>
}
        </div>
    )


}
export default withTranslation()((withRouter(UnHome)))