import React, { useEffect } from "react";
import style from "./replica.module.scss";
import { Carousel } from "react-bootstrap";
import { useState } from "react";
import ReactPlayer from "react-player";
import genius1 from "../../../assets/images/genius-1.jpg";
import genius2 from "../../../assets/images/genius-2.jpg";
import genius3 from "../../../assets/images/genius-3.jpg";
import genius4 from "../../../assets/images/genius-4.jpg";
import genius5 from "../../../assets/images/genius-5.jpg";

function Homecontent(props) {
  console.log("homeconmtent", props);

  return (
    <section className="main-body-section" id="home">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="heading-component-1 mt-4"  style={{ margin: '0 14px', radius: '50%'}}>
            {props.data.homeServiceTitle ? (
              <h1>{props.data.homeServiceTitle}</h1>
            ) : (
              <h1>WELCOME TO GENIUS BALANCING</h1>
            )}
          </div>
        </div>
      </div>

      {/* <hr /> */}

      <div className="video-text-container">
        {/* <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12'> */}
        <div className="video-section-1">
          <div className="slick-images">
            <div className="container">
              <Carousel
                interval={null}
                style={{ height: "100%", width: "100%" }}
                fade
              >
                <Carousel.Item>
                  {props.data.left_type === "video" ? (
                    <ReactPlayer
                      url={props.data.homeServiceVideoL}
                      controls={true}
                      pip={true}
                      width="100%"
                    />
                  ) : (
                    <img
                      src={props.data.homeServiceVideoL}
                      // style={{ width: "100%", height: "100%" }}
                      className="d-block w-100"
                    />
                  )}
                </Carousel.Item>
                <Carousel.Item>
                  {props.data.center_type === "video" ? (
                    <ReactPlayer
                      url={props.data.homeServiceImage}
                      controls={true}
                      pip={true}
                      width="100%"
                    />
                  ) : (
                    <img
                      src={props.data.homeServiceImage}
                      className="d-block w-100"
                      // style={{ width: "100%", height: "100%" }}
                    />
                  )}
                </Carousel.Item>
                <Carousel.Item>
                  {props.data.right_type === "video" ? (
                    <ReactPlayer
                      url={props.data.homeServiceVideoR}
                      controls={true}
                      pip={true}
                      width="100%"
                    />
                  ) : (
                    <img
                      src={props.data.homeServiceVideoR}
                      className="d-block w-100"
                      // style={{ width: "100%", height: "100%" }}
                    />
                  )}
                </Carousel.Item>

    
              </Carousel>
            </div>
          </div>
          <div>
            {/* <p className="p0"><strong>Thousands of apps aim to empower smartphone users to change their behaviors and manage their own health conditions, but only a handful of these apps can actually analyze or perform a test; and even fewer apps can both analyze and balance the Human Bio-field.</strong></p> */}
            {props.data.homeLeftText ? (
              <p className="p0">
                <strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        props.data?.homeLeftText && props.data?.homeLeftText,
                    }}
                  ></div>
                </strong>
              </p>
            ) : (
              <p className="p0">
                <strong>
                  Thousands of apps aim to empower smartphone users to change
                  their behaviors and manage their own health conditions, but
                  only a handful of these apps can actually analyze or perform a
                  test; and even fewer apps can both analyze and balance the
                  Human Bio-field.
                </strong>
              </p>
            )}
          </div>
        </div>
        {/* </div> */}
        {/* <div className='col-lg-6 col-md-6 col-sm-12'> */}
        <div className="video-section-2">
          <p className="p1"  >
            <div
            // style={{color:'#000'}}       
              dangerouslySetInnerHTML={{
                __html:
                  props.data?.homeServiceText && props.data?.homeServiceText,
              }}
            ></div>
          </p>
          {/* <p class="p1">Genius Balancing is a subsidiary to Insight Health Apps, the manufacturer of The Genius Insight Biofeedback App and other Information Coded Biofeedback Apps.</p><br />
                    <p className="p2">Genius Balancing is the practitioner portal for Genius Insight Biofeedback Practitioners.</p><br />
                    <p className="p3">Our patented voice spectral analysis process identifies stress in the human bio-field. Our Mental wellness apps stimulate a sense of well-being using sound healing and PEMF therapy as a subtle form of biofeedback.</p> */}
        </div>
        {/* </div> */}
        {/* </div> */}
      </div>
      {/* <hr /> */}
    </section>
  );
}

export default Homecontent;
