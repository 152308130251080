import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { Header, Banner, Plans, About, Contact, Footer, Scrollbutton } from '../../components/replica';
import { useParams, useHistory } from 'react-router-dom';
import ReplicaSiteService from '../../../service/replica/replica';
import AppInfoservice from '../../../service/common/Appinfo';
import Services from '../../components/replica/Services';
import Homecontent from '../../components/replica/Homecontent';
// import Plans from '../../components/replica/Plans';
import BookingForm from '../../components/replica/BookingForm';
import { Helmet } from 'react-helmet';
function Home() {
    const path = useParams();
    console.log("path...", path)
    const history = useHistory();
    const service = new ReplicaSiteService();
    const app = new AppInfoservice();


    const [state, setState] = useState({
        header_data: [],
        policy_data: [],
        banner_data: [],
        services_data: [],
        feedback_data: [],
        home_data: [],
        remote_data: [],
        loding: true,
        logo: '',
        service_status: false,
        profileImg: ''

    })



    useEffect(() => {

        service.getHeader(path.user_name).then((res) => {
            if (res.status) {
                console.log('dataaa', res.data)
                getBanner();
                getPolicy();
                getServices();
                getRemoteSessions();
                getBiofeedback();
                getHomeContent();
                setState(prevState => ({
                    ...prevState,
                    header_data: res.data,
                    loding: false,
                    service_status: res.data.serviceShow,
                    profileImg: res.data.profile_pic

                }));
            } else {
                alert("Invalid Url")

            }

        })
        app.Appinfo().then((res) => {
            console.log(res)
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    logo: res.data.company_info.login_logo,

                }));

            }


        })

    }, [])


    const getServices = () => {
        service.getServices(path.user_name).then((res) => {
            if (res.status) {
                setState((prev) => ({
                    ...prev,
                    services_data: res.data,

                }))
            } else {

            }
        })
    }

    const getRemoteSessions = () => {
        service.getRemotesessions(path.user_name).then((res) => {
            if (res.status) {
                setState((prev) => ({
                    ...prev,
                    remote_data: res.data
                }))
            } else {

            }
        })
    }


    const getBiofeedback = () => {
        service.getBiofeedBack(path.user_name).then((res) => {
            if (res.status) {
                setState((prev) => ({
                    ...prev,
                    feedback_data: res.data
                }))
            } else {

            }
        })
    }
    const getHomeContent = () => {
        service.getHomecontent(path.user_name).then((res) => {
            if (res.status) {
                setState((prev) => ({
                    ...prev,
                    home_data: res.data
                }))
            } else {

            }
        })
    }
    const getBanner = () => {
        service.getBanner(path.user_name).then((res) => {

            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    banner_data: res.data

                }));
            } else {
                alert("Invalid Url")
            }

        })

    }
    const getPolicy = () => {
        service.getPolicy(path.user_name).then((res) => {
            console.log('policy', res)
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    policy_data: res.data

                }));
            } else {

            }

        })

    }


    return (
        <div className="h-100">

            {!state.loding &&
                <>
                    {state.header_data &&
                        <Header
                            data={state.header_data}
                            user_name={path.user_name}
                            logo={state.logo}
                            showmenu={true}
                        />

                    }

                    {state.banner_data && state.policy_data &&
                        <Banner
                            data={state}
                            header_data={state.header_data}
                        />
                    }

                    <Homecontent
                        data={state.home_data}
                    />



                    <Plans
                        data={state.feedback_data}
                    />



                    <About
                        data={state.remote_data}
                    />

                    {
                        state.service_status && 
                        <Services
                            data={state.services_data}
                        />
                    }

                    {/* <Contact
                        data={state}
                        user_name={path.user_name}
                    /> */}

                    <div className='container'>
                        <BookingForm
                            data={state}
                            user_name={path.user_name}
                            profileImg={state.profileImg}
                        />
                    </div>


                    <Scrollbutton />

                    <Footer
                        data={state}
                        user_name={path.user_name}
                    />




                </>

            }




        </div>
    );

}

export default withTranslation()(withRouter(Home))