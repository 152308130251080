import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../auth/auth.module.scss";
import ReplicaSiteService from "../../../service/replica/replica";
import { AlertMessage } from "../common";
import { useState } from "react";
import contactimg from "../../../assets/images/contact-us.png";
import bookImg from "../../../assets/images/img2.jpg";
import Defaultpic from "../../../assets/images/nophoto/no_photo.jpg";
import Userimage from "./Userimage";

const stylee = {
  border: "1px solid #dc3545",
};
function BookingForm(props) {
  console.log("BookingForm", props);
  const { t } = useTranslation();
  const service = new ReplicaSiteService();
  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
    subjectline: "",
    comments: "",
    valid: true,
    user_name: props.user_name,
    FormError: {
      name: "",
      email: "",
      phone: "",
      subjectline: "",
      comments: "",
    },
    alert: {
      show: false,
      comments: "",
      type: "",
    },
  });

  const [url, setUrl] = useState();
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target; //get the field name
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validEmail = (value, name) => {
    const FormError = state.FormError;
    var regexPattern = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
    if (name == "email" && !regexPattern.test(value)) {
      FormError.email = "invalid";
    }
  };

  const validPhone = (value, name) => {
    const FormError = state.FormError;
    var regexPattern = new RegExp(/^[0-9-+]+$/);
    if (name == "phone" && !regexPattern.test(value)) {
      FormError.phone = "invalid";
      setState((prevState) => ({
        ...prevState,
        valid: false,
      }));
    }
  };

  const requiredValid = (value, name) => {
    const FormError = state.FormError;
    switch (name) {
      case "name":
        FormError.name = value.length === 0 ? "required" : "";
        break;
      case "email":
        FormError.email = value.length === 0 ? "required" : "";
        break;
      case "phone":
        FormError.phone = value.length === 0 ? "required" : "";
        break;
      // case "subjectline":
      //     FormError.subjectline = value.length === 0 ? "required" : "";
      //     break;
      case "comments":
        FormError.comments = value.length === 0 ? "required" : "";
        break;
      default:
        break;
    }

    setState((prevState) => ({
      ...prevState,
      FormError,
    }));
  };

  const formValid = (formError) => {
    console.log("formerrroe", formError);
    let valid = true;
    Object.values(formError).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  };
  const handleClick = (e) => {
    console.log("==========================");
    e.preventDefault();

    requiredValid(state.name, "name");
    requiredValid(state.email, "email");
    requiredValid(state.phone, "phone");
    requiredValid(state.subjectline, "subjectline");
    requiredValid(state.comments, "comments");
    validPhone(state.phone, "phone");
    validEmail(state.email, "email");
    if (formValid(state.FormError)) {
      let formData = {
        name: state.name,
        email: state.email,
        phone: state.phone,
        subjectline: state.subjectline,
        comments: state.comments,
        replica_user: state.user_name,
      };
      service.postBookingdata(formData).then((res) => {
        console.log("bghjghj", res);
        if (res.status) {
          document.getElementById("create-course-form").reset();
          setState((prev) => ({
            ...prev,
            name: "",
            email: "",
            phone: "",
            subjectline: "",
            alert: {
              show: true,
              type: "success",
              header: t("Replica."),
              message: t("Replica." + res.data.message),
            },
          }));
        } else {
          setState((prev) => ({
            ...prev,
            alert: {
              show: true,
              type: "danger",
              header: t("Replica."),
              message: t("Replica." + res.data.description),
            },

            // show: true,
            // type: "danger",
            // header: t("Replica."),
            // message: t("Replica." + res.error.description),
          }));
        }
      });
    } else {
    }
  };

  const notifyDissmissed = () => {
    setState((prev) => ({
      ...prev,
      alert: {
        show: false,
      },
    }));
  };

  const onFileChange = (event) => {
    console.log("fileee", event.target.files[0]);
    // Update the state
    if (event.target.files[0]) {
      let formdata = new FormData();
      let service = new ReplicaSiteService();
      formdata.append("image", event.target.files[0]);
      service.uploadImage(formdata).then((res) => {
        if (res.status) {
          setUrl(URL.createObjectURL(event.target.files[0]));
          // props.notify('success', 'Common.success', 'Common.profileUpdatedSuccesfully');
          setState((prev) => ({
            ...prev,
            alert: {
              show: true,
              type: "success",
              header: t("Replica."),
              message: t("Replica.imageupdated"),
            },
          }));
          setTimeout(() => {
            props.getData();
          }, 1000);
        } else {
          if (res.error.code === 1038) {
            // props.notify('error', 'Common.error', 'validation.tooManyUploadLimit');
            setState((prev) => ({
              ...prev,
              alert: {
                show: true,
                type: "success",
                header: t("Replica."),
                message: t("Replica.tooManyUploadLimit"),
              },
            }));
          } else if (res.error.code === 1017) {
            // props.notify('error', 'Common.error', 'validation.fileTypeNotSupported');
            setState((prev) => ({
              ...prev,
              alert: {
                show: true,
                type: "success",
                header: t("Replica."),
                message: t("Replica.fileTypeNotSupported"),
              },
            }));
          } else if (res.error.code === 1018) {
            // props.notify('error', 'Common.error', 'validaion.fileTypeExceeded');
            setState((prev) => ({
              ...prev,
              alert: {
                show: true,
                type: "success",
                header: t("Replica."),
                message: t("Replica.fileTypeExceeded"),
              },
            }));
          } else if (res.error.code === 1024) {
            // props.notify('error', 'Common.error', 'Common.error_file_upload');
            setState((prev) => ({
              ...prev,
              alert: {
                show: true,
                type: "success",
                header: t("Replica."),
                message: t("Replica.error_file_upload"),
              },
            }));
          } else if (res.error.code === 1002) {
            // history.push('/logout');
          } else if (res.error.code === 1032) {
            // props.notify('error', 'Common.error', 'validaion.chooseFile')
            setState((prev) => ({
              ...prev,
              alert: {
                show: true,
                type: "success",
                header: t("Replica."),
                message: t("Replica.chooseFile"),
              },
            }));
          }
        }
      });
    }
  };

  const onDeletePic = (event) => {
    if (window.confirm(t("validation.sureWantToDelete"))) {
      let service = new ReplicaSiteService();

      service.deleteImage().then((res) => {
        if (res.status != 404) {
          props.getData();

          //   props.notify('success', 'Common.success', 'Common.profileDeletedSuccesfully');
          setState((prev) => ({
            ...prev,
            alert: {
              show: true,
              type: "success",
              header: t("Replica."),
              message: t("Replica.dltimage"),
            },
          }));
        } else if (res.status == 404) {
          //   props.notify('error', 'Common.error', 'Common.cantdeletedefaultpic');
          setState((prev) => ({
            ...prev,
            alert: {
              show: true,
              type: "danger",
              header: t("Replica."),
              message: t("Replica.cantdeletedefaultpic"),
            },
          }));
        }
      });
    }
  };
  console.log("msgggggg", state.alert.show);
  return (
    <>
      {state.alert.show && (
        <AlertMessage
          message={state.alert.message}
          dismiss={notifyDissmissed}
          type={state.alert.type}
          header={state.header}
          show={state.alert.show}
        />
      )}
      <section class="contactussection" id="contact">
        <div
          /* className="heading-component-1 mb-4" */ style={{
            color: "#000",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontWeight: 'bold' }}>
            <br />
            Schedule a free scan now
            <br />
            <br />
          </h1>
        </div>

        <div
          className="contact-us-div"
          style={{ borderRadius: "50px", padding: "20px" }}
        >
          <div
            style={{ justifyContent: "center", width: "100%", display: "flex" }}
          >
            {/* <div style={{ width: '250px', height: '300px', border: 'dotted', paddingTop: '4em' }}>
                            <Userimage
                                image={props.profileImg}
                                alert={state.alert}
                                onFileChange={onFileChange}
                                onDeletePic={onDeletePic}
                                url={url}
                            />
                        </div> */}

            {/* {
                            props.profileImg ?
                                <img src={props.profileImg} style={{ width: '250px', height: '300px' }} />
                                : <div style={{ width: '250px', height: '300px', border: 'dotted', paddingTop: '4em' }}>
                                    <center><span style={{ color: 'red' }}>Please Update <br /> your profile picture</span></center>
                                </div>
                        } */}
            {props.profileImg && (
              <img
                src={props.profileImg}
                style={{ width: "250px", height: "300px" }}
              />
            )}
          </div>
          <div>
            <div>
              <h1
                style={{
                  color: "#0d0d0d",
                  textAlign: "center",
                  fontStyle: "normal",
                  fontFamily: "Poppins!important",
                  fontWeight: "400",
                }}
              >
                We Look Forward To Connecting With You
              </h1>
            </div>
            <div>
              <form id="create-course-form">
                <div className="from-Label">
                  <div>
                    {/* <label>YOUR PHONE NUMBER</label>
                                    <input type="text" name="Your Phone Number" /> */}
                    <label for="" style={{ color: "#0d0d0d" }}>
                      {t("Replica.phone")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className={`form-control
                                                         ${
                                                           state.FormError.phone
                                                             ? styles.formHaserror
                                                             : ""
                                                         }`}
                      // className="form-control"
                      type="text"
                      name="phone"
                      min={0}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #0d0d0d",
                        outline: "none",
                        color: '#0d0d0d'
                      }}

                      // placeholder={t("Replica.phone")}
                    />
                    <span
                      className={`${
                        state.FormError.phone ? styles.isInvalid : "d-none"
                      }`}
                    >
                      {t("Replica.Error." + state.FormError.phone, {
                        field: "phone",
                      })}
                    </span>
                  </div>
                  <div>
                    {/* <label>YOUR NAME</label>
                                    <input type="text" name="Your Name" /> */}
                    <label for="" style={{ color: "0d0d0d" }}>
                      {t("Replica.name")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className={`form-control
                                             ${
                                               state.FormError.name
                                                 ? styles.formHaserror
                                                 : ""
                                             }`}
                      type="text"
                      name="name"
                      onChange={handleChange}
                      // placeholder={t("Replica.name")}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #0d0d0d",
                        outline: "none",
                        color: '#0d0d0d'
                      }}
                    />
                    <span
                      className={`${
                        state.FormError.name ? styles.isInvalid : "d-none"
                      }`}
                    >
                      {t("Replica.Error." + state.FormError.name, {
                        field: "name",
                      })}
                    </span>
                  </div>
                  <div>
                    {/* <label>YOUR EMAIL</label>
                                    <input type="text" name="Your Email" /> */}
                    <label for="" style={{ color: "#0d0d0d" }}>
                      {t("Replica.email")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className={`form-control
                                            ${
                                              state.FormError.email
                                                ? styles.formHaserror
                                                : ""
                                            }`}
                      type="text"
                      name="email"
                      onChange={handleChange}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #0d0d0d",
                        outline: "none",
                        color: "#0d0d0d",
                      }}
                      // placeholder={t("Replica.email")}
                    />
                    <span
                      className={`${
                        state.FormError.email ? styles.isInvalid : "d-none"
                      }`}
                    >
                      {t("Replica.Error." + state.FormError.email, {
                        field: "email",
                      })}
                    </span>
                  </div>
                  <label for="" style={{ color: "#0d0d0d" }}>
                    {t("Replica.message")}
                    <span className="text-danger">*</span>
                  </label>

                  {/* <input
                                    className="form-control"
                                    type="text"
                                    name="comments"
                                    onChange={handleChange}
                                // placeholder={t("Replica.email")}
                                />
                                <span
                                    className={`${state.FormError.comments
                                        ? styles.isInvalid
                                        : "d-none"
                                        }`}
                                >
                                    {t("Replica.Error." + state.FormError.comments, {
                                        field: "comments",
                                    })}
                                </span> */}

                  <textarea
                    cols={10}
                    rows={10}
                    className={`form-control
                                        ${
                                          state.FormError.comments
                                            ? styles.formHaserror
                                            : ""
                                        }`}
                    type="text"
                    name="comments"
                    onChange={handleChange}
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #0d0d0d",
                      outline: "none",
                      color: "#0d0d0d",
                    }}
                  ></textarea>

                  <span
                    className={`${
                      state.FormError.comments ? styles.isInvalid : "d-none"
                    }`}
                  >
                    {t("Replica.Error." + state.FormError.comments, {
                      field: "comments",
                    })}
                  </span>

                  <div style={{ textAlign: "center" }}>
                    <button
                      className="contact-submit-btn mt-4"
                      style={{ color: "#0d0d0d" }}
                      onClick={handleClick}
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <hr />
      </section>
    </>
  );
}

export default BookingForm;
