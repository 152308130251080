import React, { useEffect } from 'react';
import style from './replica.module.scss';
import { Row, Col, Container, Navbar } from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';
import ReactPlayer from 'react-player';
function Plans(props) {

    //-----------------------biofeedback section--------------------------
    return (
        <section className="visualize-cont-sec section_view_2" id='biofeedback'>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <div className="shogund-heading-component">

                        {/* WELCOME TO THE GENIUS INSIGHT BIOFEEDBACK APP. <br />THE MOST ADVANCED BIOFEEDBACK APP IN THE WORLD TODAY!<br /><br />SCHEDULE YOUR FREE GENIUS SCAN TODAY<br /> */}

                        {
                            props.data.homeDescriptionTitle ?
                                <h1><div dangerouslySetInnerHTML={{ __html: props.data?.homeDescriptionTitle && props.data?.homeDescriptionTitle }}></div></h1>
                                :
                                <h1>WELCOME TO THE GENIUS INSIGHT BIOFEEDBACK APP. <br />THE MOST ADVANCED BIOFEEDBACK APP IN THE WORLD TODAY!<br /><br />SCHEDULE YOUR FREE GENIUS SCAN TODAY<br /></h1>
                        }


                    </div>
                </div>
            </div>

            <div className="heading-component-1" style={{ margin: '0 14px', radius: '50%'}}>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        {
                            props.data.geniusBiofeedbackTitle ?
                                <h1>
                                    {props.data?.geniusBiofeedbackTitle}

                                </h1>

                                : <h1>GENIUS BIOFEEDBACK SESSIONS <br />WHAT TO EXPECT</h1>

                        }
                    </div>
                </div>

                {/* <h1>GENIUS BIOFEEDBACK SESSIONS <br />WHAT TO EXPECT</h1> */}




            </div>
            <hr />

            <div className="video-2-main">
                <div className="video-2-main-1">
                    <div className='container'>
                        <Carousel interval={null} style={{ height: '100%', width: '100%' }} fade>
                            <Carousel.Item>
                                {
                                    props.data.left_type === "video" ?
                                        <ReactPlayer
                                            url={props.data.bioFeadbackVideoL}
                                            controls={true}
                                            pip={true}
                                            
                                            width="100%"

                                        />
                                        : <img src={props.data.bioFeadbackVideoL} style={{ width: "100%", height: "100%" }} />

                                }
                            </Carousel.Item>
                            <Carousel.Item>

                                {
                                    props.data.center_type === "video" ?
                                        <ReactPlayer
                                            url={props.data.bioFeadbackImage}
                                            controls={true}
                                            pip={true}
                                            
                                            width="100%"
                                        />
                                        : <img src={props.data.bioFeadbackImage} style={{ width: "100%", height: "100%" }} />

                                }

                            </Carousel.Item>
                            <Carousel.Item>

                                {
                                    props.data.right_type === "video" ?
                                        <ReactPlayer
                                            url={props.data.bioFeadbackVideoR}
                                            controls={true}
                                            pip={true}
                                            
                                            width="100%"
                                        />
                                        : <img src={props.data.bioFeadbackVideoR} style={{ width: "100%", height: "100%" }} />

                                }

                            </Carousel.Item>


                        </Carousel>
                    </div>

                    {/* <div>
                        <iframe width="100%" height="375px" src="https://www.youtube.com/embed/g1fjBNJtomE"></iframe>
                    </div> */}
                    <div>
                        {
                            props.data.geniusBiofeedbackLeftText ?
                                <h1><div dangerouslySetInnerHTML={{ __html: props.data?.geniusBiofeedbackLeftText && props.data?.geniusBiofeedbackLeftText }}></div></h1>
                                : <h1> add your data</h1>
                        }

                    </div>
                </div>
                <div className="video-2-main-2">
                    <div>
                        <h1>VISUALIZE THE BODY LIKE NEVER BEFORE</h1>
                    </div>
                    <div>
                        <h2>
                            <span>
                                <div dangerouslySetInnerHTML={{ __html: props.data?.bioFeadbackText && props.data?.bioFeadbackText }}></div>
                                {/* A typical session lasts betwen 25 to 45 minutes. Your Genius facilitator will attach a
                                harness to your wrist(s) and proceed to record your voice using the Genius App.
                                <br />
                                In a matter of seconds your response to thousands of substances will be instantly available.
                                <br />
                                Together with your facilitator you will review your results and select the items of concern
                                and create a custom playlist of energetic tones & frequencies to help your body achieve a
                                state of balance and harmony.
                            </span>
                            <br />
                            <br />
                            <span>
                                The Genius Insight Quantum Biofeedback App analyzes your body using voice spectral analysis
                                to determine specific stressors in your body. Identify hormonal imbalances, viruses,
                                bacteria, and pathogenic activity. Review spinal energy flow, electro-acupuncture, aura
                                analysis, and more. */}
                            </span>
                        </h2>
                    </div>
                </div>
            </div>
            <hr />
        </section>






















    )
}

export default Plans