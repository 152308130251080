import React from 'react'
import { Helmet } from 'react-helmet'
import companyName from '../../../store/mobxStore/companyName'
import { useTranslation } from 'react-i18next'
import { PageTitle } from '../../components/common'
import ServiceContent from '../../components/ReplicaContentManagement/ServiceContent'

function ServiceManagement() {
    const { t } = useTranslation();
    return (
        <div className='h-100'>
            <Helmet>
                <title>{companyName.companyName}|{t('Sidemenu.services')}</title>
            </Helmet>
            <PageTitle title="ServiceManagement"/>
          <ServiceContent/>
        </div>
    )
}

export default ServiceManagement