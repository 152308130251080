import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Accordion, Col, Row, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IMG_URL } from "../../../configuration/apiconfig";
import style from "./mail.module.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import AppInfoservice from "../../../service/common/Appinfo";
import { addNotify, addMail } from "../../../store/action";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment";

function ViewMail(props) {
  console.log('viewmaillllll',props.data)
  const { currentTheme } = useSelector((state) => state.Theme);
  const [state, setState] = useState([]);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    const service = new AppInfoservice();
    setState(Object.values(props.data));

    service.getNotification().then((res) => {
      if (res.status) {
        dispatch(
          addMail(
            res.data.mail_details.mail_count,
            res.data.mail_details.mail_data
          )
        );
        dispatch(addNotify(res.data.notification_details));
      }
    });
  }, [props.data]);

  const dark_mode_styleObj = {
    color: 'white',
  };
  const styleObj = {
    color: 'black',
  };

  const htmlDecode = (input) => {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length === 0
      ? ""
      : e.childNodes[0].nodeValue
      ? e.childNodes[0].nodeValue
      : input;
  };

  const { t } = useTranslation();
  return (
    <div>
      {params.type == "user" ||
      params.type == "ext_mail_user" ||
      params.type == "admin" ? (
        <>
          <div
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_headerContent}`
                : `${style.headerContent}`
            }`}
          >
            <h4>{state[0] && [state[0].subject ?? state[0].mailadsubject]}</h4>
          </div>
          <div className={style.ViewMailContent}>
            {state.length === 0 && (
              <Accordion
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_panelHeading}`
                    : `${style.panelHeading}`
                }`}
              >
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    className={style.panelHeading}
                  >
                    <div className={style.panelHeadingWrapper}>
                      <Row>
                        <Col md={1}>
                          <SkeletonTheme
                            baseColor="#202020"
                            highlightColor="#444"
                          >
                            <Skeleton
                              duration={2}
                              delay={1}
                              circle={true}
                              height={50}
                              width={50}
                            />
                          </SkeletonTheme>
                        </Col>
                        <Col md={11}>
                          <SkeletonTheme
                            baseColor="#202020"
                            highlightColor="#444"
                          >
                            <p>
                              <Skeleton width={200} duration={2} delay={1} />
                            </p>
                            <p>
                              <Skeleton width={200} duration={2} delay={1} />
                            </p>
                          </SkeletonTheme>
                        </Col>
                      </Row>
                    </div>
                  </Accordion.Toggle>
                </Card>
              </Accordion>
            )}
            {state.map((e, index) => (
             
              <Accordion
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_mailPanel}`
                    : `${style.mailPanel}`
                }`}
                key={index}
              >
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    className={`${
                      currentTheme == "theme-dark"
                        ? `${style.dark_mode_panelHeading}`
                        : `${style.panelHeading}`
                    }`}
                  >
                    <div className={style.panelHeadingWrapper}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={IMG_URL + "profile_picture/mail_pro.png"}
                            className={style.profileImage}
                          />
                        </Col>
                        <Col md={11}>
                          {e.is_sent_mail ? (
                            <>
                              <span>{t("Common.me")}</span>
                              <span style={{ color: "#fda341" }}>
                                {" "}
                                ({t("Common.sent")}){" "}
                              </span>
                              <br />
                              <span>{e.date ?? e.mailadiddate}</span>
                            </>
                          ) : (
                            <>
                              <span>{e.fullname}</span>
                              <span style={{ color: "#fda341" }}>
                                {" "}
                                ({t("Common.received")})
                              </span>
                              <br />
                              <span>({e.user_name ?? e.to})</span>
                              <br />

                              <span>
                                {moment(e?.date).format("yyyy-MM-DD") ??
                                  moment(e?.mailadiddate).format("yyyy-MM-DD")}
                              </span>
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0" className={`accordian`}>
                    <Card.Body
                      className={`${
                        currentTheme === "theme-dark"
                          ? `${style.dark_mode_mailMessage}`
                          : `${style.mailMessage}`
                      }`}
                      dangerouslySetInnerHTML={{ __html: htmlDecode(e.msg) }}
                    />
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            ))}
            {state.length > 0 && props.reply && (
              <div className={style.replyContent}>
                <div className={style.replyMailFixed}>
                  <span>{t("Common.clickHereTo")} </span>
                  <span
                    className="text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.replyMessage(state && state[state.length - 1].id);
                    }}
                  >
                    {" "}
                    {t("Common.reply")}
                  </span>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div>
          <div className={style.headerContent}>
            <h4>
              {props.data.contact_name &&
                props.data.contact_name + " contacted you"}
            </h4>
          </div>
          <div className={style.ViewMailContent}>
            {
              <Accordion className={style.mailPanel}>
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    className={style.panelHeading}
                  >
                    <div className={style.panelHeadingWrapper}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={IMG_URL + "profilePic/mail_pro.png"}
                            className={style.profileImage}
                          />
                        </Col>
                        <Col md={11}>
                          {props.data["is_sent_mail"] ? (
                            <>
                              <span>{t("Common.me")}</span>
                              <span style={{ color: "#fda341" }}>
                                {" "}
                                ({t("Common.sent")}){" "}
                              </span>
                              <br />
                              <span>
                                {moment(props.data["date"]).format(
                                  "yyyy-MM-DD"
                                ) ??
                                  moment(props.data["date"]).format(
                                    "yyyy-MM-DD"
                                  )}
                              </span>
                            </>
                          ) : (
                            <>
                              <span>
                                {props.data["contact_email"] ??
                                  props.data["contact_email"]}
                              </span>
                              <span style={{ color: "#fda341" }}>
                                {" "}
                                {props.data["contact_name"] &&
                                  t("Common.received")}
                              </span>
                              <br />
                              <span>
                                {" "}
                                {props.data["contact_name"] &&
                                  props.data["contact_name"]}
                              </span>
                              <br />
                              <span>
                                {moment(props.data["date"]).format(
                                  "yyyy-MM-DD"
                                ) ??
                                  moment(props.data["date"]).format(
                                    "yyyy-MM-DD"
                                  )}
                              </span>
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0" className={`accordian`}>
                    <Card.Body
                      className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_mailMessage}`
                          : `${style.mailMessage}`
                      }`}
                      dangerouslySetInnerHTML={{
                        __html:
                          props.data["contact_info"] ??
                          props.data["contact_info"],
                      }}
                    />
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            }
          </div>
        </div>
      )}
    </div>
  );
}
export default ViewMail;
