import React, { useEffect } from 'react';
import style from './replica.module.scss';
import { Row, Col, Container, Navbar } from 'react-bootstrap';
import { Carousel } from 'react-bootstrap'
import ReactPlayer from 'react-player';
import remoteImg from '../../../assets/images/img.jpg'
import iphone from '../../../assets/images/iphone-icon.jpg'
import gpay from '../../../assets/images/gplay-store-icon.jpg'
function About(props) {

    //----------------------remote section---------------------------

    return (
        <section className="visualize-cont-sec" id='remote'>
            <div className="heading-component-1"  style={{ margin: '0 14px', radius: '50%'}}>

                {
                    props.data.remoteSessionTitle ?
                        <h1>
                            <div dangerouslySetInnerHTML={{ __html: props.data?.remoteSessionTitle && props.data?.remoteSessionTitle }}></div>
                        </h1>
                        : <h1>REMOTE BIOFEEDBACK SESSIONS <br />
                            SCHEDULE YOURS TODAY</h1>
                }


            </div>

            <div className="video-image-slider" style={{ margin: '8px 0' }}>
                <div className="video-image-slider-slick">
                    <div className='container'>
                        <Carousel interval={null} style={{ height: '100%', width: '100%' }} fade>
                            <Carousel.Item>
                                {
                                    props.data.left_type === "video" ?
                                        <ReactPlayer
                                            url={props.data.remoteSessionVideoL}
                                            controls={true}
                                            width="100%"

                                        />
                                        : <img src={props.data.remoteSessionVideoL} className='w-100' />

                                }
                                {/* <iframe width="854" height="480" src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4" frameborder="0" allowfullscreen style={{width:'100%'}}></iframe> */}
                            </Carousel.Item>
                            <Carousel.Item>

                                {
                                    props.data.center_type === "video" ?
                                        <ReactPlayer
                                            url={props.data.remoteSessionImage}
                                            controls={true}
                                            width="100%"
                                            pip={true}

                                        />
                                        : <img src={props.data.remoteSessionImage} className="d-block w-100" />

                                }

                            </Carousel.Item>
                            <Carousel.Item>

                                {
                                    props.data.right_type === "video" ?
                                        <ReactPlayer
                                            url={props.data.remoteSessionVideoR}
                                            controls={true}
                                            pip={true}
                                           
                                            width="100%"
                                        />
                                        : <img src={props.data.remoteSessionVideoR} className='w-100' />

                                }
                            </Carousel.Item>

                            {/* <Carousel.Item>
                            <img src={remoteImg} />
                        </Carousel.Item>
                        <Carousel.Item>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/gGyqWQrkIIw"></iframe>
                        </Carousel.Item> */}

                        </Carousel>
                    </div>

                </div>
                <div className="video-2-main-2">
                    <div>
                        <h1>Genius Insight Balancing From Anywhere In The World</h1>
                    </div>
                    <div>

                        <h2 style={{ fontWeight: '400' }}>
                            <span>
                                <div dangerouslySetInnerHTML={{ __html: props.data?.remoteSessionText && props.data?.remoteSessionText }}></div>
                                {/* Regardless of your location, you can now schedule a remote Genius Insight balancing with a
                                Genius facilitator from anywhere in the world with The Insight Quanta Capsule.
                                <br />
                                <br />
                                Simplly download the Insight Quanta Caspule from the app store (iOS & Android compatable)
                                and create your user profile.
                                <br /><br />
                                Upload your voice recording and we take care of the rest! Your voice is uploaded to our
                                encrypted server for analysis.
                                <br /><br />
                                Your Genius facilitator downlods your voice into the Genius App to perform the analysis and
                                investigate your imbalances. Once the imbalances have been identified, your Genius
                                facilitator will create a custom playlist of energetic healing tones and frequencies. Simply
                                download this custom playlist into your Quanta Capsule and listen to these energetic
                                frequencies for up to 7 days.
                                <br /><br />
                                After 7 days, contact your Genius facilitator for a new series of custom playlists. */}
                            </span>
                            <br />
                        </h2>
                        {/* <p className="icon-text" style={{ marginBottom: "25px" }}><strong>Download your Insight Quanta Capsule
                            today.</strong></p><br />
                        <p className="icon-text"><strong>FREE DOWNLOAD!</strong></p>
                        <div class="icon-container">
                            <img src={iphone} />
                            <img src={gpay} />
                        </div> */}
                    </div>
                </div>
            </div>
            <hr />
        </section>


    )
}

export default About





