import React from 'react'
import { Card } from 'react-bootstrap'
import style from './replica.module.scss'
import { Carousel } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import serviceImg from '../../../assets/images/img2.jpg'
import { useState } from 'react'
function Services(props) {

    console.log('services', props.data.left_type)
    const [currentSlide, setcurrentSlide] = useState(0)

    console.log('currentslide', currentSlide)
    return (
        <section className="visualize-cont-sec2 mt-5" id='our-service'>
            <div className="heading-component-1"  style={{ margin: '0 14px', radius: '50%'}}>
                <h1><br />OUR SERVICES<br /><br /></h1>
            </div>

            <div className="our-services">
                {/* <div style={{ justifyContent: 'center', width: '100%', display: 'flex' }}> */}
                {/* <img src={serviceImg} style={{width:'250px',height:'300px'}} /> */}
                <div className='container mt-2'>
                    <Carousel interval={null} style={{ height: '100%', width: '100%' }} activeIndex={currentSlide} onSelect={(index) => { setcurrentSlide(index) }} fade>
                      
                        <Carousel.Item>
                            {/* <iframe width="854" height="480" src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4" frameborder="0" allowfullscreen style={{width:'100%'}}></iframe> */}
                            {
                                props.data.left_type === "video" ?
                                    <ReactPlayer
                                        url={props.data.ourServicesVideoL}
                                        controls={true}
                                        pip={true}
                                        
                                        width="100%"
                                    />
                                    : <img src={props.data.ourServicesVideoL}
                                        className="d-block w-100"
                                    // style={{ width: "100%", height: "50%" }} 
                                    />

                            }
                        </Carousel.Item>
                        <Carousel.Item>

                            {
                                props.data.center_type === "video" ?
                                    <ReactPlayer
                                        url={props.data.ourServicesImage}
                                        controls={true}
                                        pip={true}
                                        
                                        width="100%"
                                    />
                                    : <img src={props.data.ourServicesImage}
                                        className="d-block w-100"
                                    // style={{ width: "100%", height: "100%" }}
                                    />

                            }
                        </Carousel.Item>
                        <Carousel.Item>

                            {
                                props.data.right_type === "video" ?
                                    <ReactPlayer
                                        url={props.data.ourServicesVideoR}
                                        controls={true}
                                        pip={true}
                                        width="100%"
                                    />
                                    : <img src={props.data.ourServicesVideoR}
                                        className="d-block w-100"
                                    // style={{ width: "100%", height: "100%" }} 
                                    />

                            }

                        </Carousel.Item>
                    </Carousel>
                </div>


                {/* </div> */}
                <div>
                    <div>
                        <h1 style={{ textAlign: 'center', fontStyle: 'normal', fontFamily: 'Poppins', fontWeight: '400' }}>
                            Genius Insight Balancing From Anywhere In The World</h1>
                    </div>
                   <p> <div dangerouslySetInnerHTML={{ __html: props.data?.ourServicesText && props.data?.ourServicesText }}></div></p>
                    {/* <p style={{ marginLeft: '0px', marginRight: '0px', textAlign: 'start' }}>Meet Ryan, a pioneer in the
                        biofeedback industry. As a child requiring speech therapy, Ryan has always been interested in
                        Biofeedback as a treatment technique. From being a patient to becoming a practitioner, Ryan has
                        witnessed the tremendous results biofeedback can offer. Over the past 20 years, Ryan has become a
                        leader in his industry; from biofeedback device training, distribution and manufacturing to
                        biofeedback app development, Ryan brings a unique blend of skills and experience to the industry.
                        His vast expertise in FDA compliance and online marketing are well sought after within the industry.
                    </p> */}
                    {/* <p style={{ marginLeft: '0px', marginRight: '0px', textAlign: 'start' }}>His work experience includes; Army
                        service in his native South Africa, university work in production engineering, entrepreneurship, and
                        several years as a wellness practitioner in Canada, the UK and South Africa. As a minister, Ryan was
                        under a guided meditation, seeking answers and a meaning to his life. While under meditation, God
                        spoke to Ryan and gifted him to tools and knowledge to create the worlds first Voice Analysis App
                        called The Quantum iLife/iNfinity App. It took Ryan over 8 months of working with designers and
                        engineers to create the first beta version. Since the first Quantum iLife/iNfinity App, Ryan has
                        gone on to produce over 30 customized app solutions based upon a principle of Interactive
                        Biofeedback Apps. His flagship app is called The Genius Insight App.</p> */}
                </div>
            </div>

            <hr></hr>
        </section>
    )
}

export default Services